import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

// @ts-expect-error false positive image import failure
import imgC1 from "../../assets/image/alert/alert-mug-1.jpg";
// @ts-expect-error false positive image import failure
import imgC2 from "../../assets/image/alert/alert-mug-2.jpg";
// @ts-expect-error false positive image import failure
import imgC3 from "../../assets/image/alert/alert-mug-3.jpg";
// @ts-expect-error false positive image import failure
import imgC4 from "../../assets/image/alert/alert-mug-4.jpg";

const SectionStyled = styled(Section)``;

const SingleImage = styled(Box)`
  display: flex;
  flex-direction: column;
  img {
    margin-bottom: 15px;
    margin-right: 15px;
    border-radius: 8px;
  }
`;

const Content2 = () => {
  return (
    <>
      {/* <!-- Content2 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0 d-flex justify-content-center">
              <Box className="d-flex">
                <SingleImage>
                  <img
                    src={imgC2}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="250"
                    data-aos-once="true"
                  />
                  <img
                    src={imgC1}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="200"
                    data-aos-once="true"
                  />
                </SingleImage>
                <SingleImage>
                  <img
                    src={imgC3}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="350"
                    data-aos-once="true"
                  />
                  <img
                    src={imgC4}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="450"
                    data-aos-once="true"
                  />
                </SingleImage>
              </Box>
            </Col>
            <Col lg="6" md={9}>
              <div>
                <Title>Build your offender database.</Title>
                <Text>
                  We have designed alert! so it's easy to add new offenders
                  complete with images, warning tags and written descriptions.{" "}
                  <br />
                  <br />
                  As users add offenders it will build you database, other
                  members will be able to browse through them like a social
                  media feed. Alert sends notifications for all new submitted
                  information keeping users informed and prepared.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content2;
