import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";

// @ts-expect-error false positive image import failure
import imgPhone from "../../assets/image/alert/alert-3.png";

const SectionStyled = styled(Section)``;

const Content3 = () => {
  return (
    <>
      {/* <!-- Content3 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col
              lg="6"
              className="mb-4 mb-lg-0 order-lg-2 d-flex justify-content-center"
            >
              <img
                src={imgPhone}
                style={{ width: "60%" }}
                alt=""
                className="img-fluid"
              />
            </Col>
            <Col lg="6" md={9} className="order-lg-1">
              <div>
                <Title>Incident reports made simple.</Title>
                <Text>
                  Alert's secure incident reports are made to be as quick and as
                  simple as possible, reducing your users administration and
                  their engagement.
                  <br />
                  <br />
                  Images help get your point across quickly and in alert they
                  are front and centre, making up a large part of your incidents
                  report. In addition allowing your users to submit and gather
                  image evidence in Alert makes your banning order
                  administration easier.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content3;
