import React from "react";
import { Helmet } from "react-helmet"
import Hero from "../sections/alert/Hero";
import Content1 from "../sections/alert/Content1";
import Content2 from "../sections/alert/Content2";
import Content3 from "../sections/alert/Content3";
import Content4 from "../sections/alert/Content4";
import Content5 from "../sections/alert/Content5";
import CTA from "../sections/alert/CTA";

import PageWrapper from "../components/PageWrapper";

const LandingPage6 = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Alert - Secure Information Sharing</title>
          <meta name="description" content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK." />
        </Helmet>
        <Hero />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <Content5 />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default LandingPage6;
