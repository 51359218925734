import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgHero from "../../assets/image/alert/alert-1.png";

const ImgRight = styled(Box)`
  @media ${device.sm} {
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="#FCFDFE" className="position-relative" pb="60px !important">
        <div className="pt-5"></div>
        <Container>
          <Row className="align-items-center position-relative">
            <Col
              md="5"
              xl="4"
              className="position-static pb-5"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <ImgRight>
                <img src={imgHero} alt="" className="img-fluid" />
              </ImgRight>
            </Col>
            <Col md="7" xl="6" className="offset-xl-1">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box>
                  <Title>Secure Information Sharing, Made Simple</Title>
                  <Box mb={4}>
                    <Text color="dark">
                      Alert! is the best secure platform for information sharing
                      and messaging between retailers and evening economy. We
                      have worked with business improvement districts and crime
                      partnerships across the country to create a modern and
                      easy to use app.
                    </Text>
                  </Box>
                  <Box>
                    <Link to="/contact">
                      <Button className="mr-3 mb-3">I want to join</Button>
                    </Link>
                    <a href="https://app.shopsafealert.co.uk" target="_blank">
                      <Button variant="light" color="primary">
                        Sign in
                      </Button>
                    </a>
                  </Box>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
