import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";

// @ts-expect-error false positive image import failure
import imgPhone from "../../assets/image/alert/alert-2.png";

const SectionStyled = styled(Section)``;

const Content3 = () => {
  return (
    <>
      {/* <!-- Content3 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col
              lg="6"
              className="mb-4 mb-lg-0 order-lg-1 d-flex justify-content-center"
            >
              <img
                src={imgPhone}
                style={{ width: "60%" }}
                alt=""
                className="img-fluid"
              />
            </Col>
            <Col lg="6" md={9} className="order-lg-2">
              <div>
                <Title>Fast &amp; compliant instant messaging.</Title>
                <Text>
                  Designed to look and feel like popular messaging apps Alert's
                  instant messaging feature will make your users feel at home,
                  while allowing you simple administration to ensure compliance.
                  Unlike standard messaging apps Alert enables you to moderate
                  the content in the chat, without this functionality it is not
                  possible to meet your GDPR obligations.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content3;
