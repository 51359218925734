import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

// @ts-expect-error false positive image import failure
import imgPhone from "../../assets/image/alert/alert-2.png";

const SectionStyled = styled(Section)``;

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

interface ThemeProps {
  theme: DefaultTheme;
}

const WidgetStyled = styled(Box)<ThemeProps>`
  padding-left: 70px;
  position: relative;
  max-width: 411px;
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 0;
    min-width: 49px;
    width: 49px;
    height: 49px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.light};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
`;

const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
  return (
    <WidgetStyled {...rest}>
      <Title variant="card" mb={2}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </WidgetStyled>
  );
};

const Content1 = () => {
  return (
    <>
      {/* <!-- Content1 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col
              lg="6"
              sm="12"
              className="mb-4 mb-lg-0 order-lg-2 d-flex justify-content-center"
            >
              <img
                src={imgPhone}
                style={{ width: "60%" }}
                alt=""
                className="img-fluid"
              />
            </Col>
            <Col lg="6" md={9} className="order-lg-1 ml-lg-10 ">
              <div>
                <Title>Secure doesn't have to mean complicated.</Title>
                <Text>
                  Alert was born with one key thing in mind - simplicity. Long
                  tedious data entry procedures and admin control would only
                  discourage its use. We built Alert! with a modern and familiar
                  look - it had to look more like a social media platform than a
                  crime report.
                </Text>
                <WidgetContainer mt={5}>
                  <Widget title="Biometric Login" mb={4}>
                    Use your face or fingerprint to login to alert! for a
                    painless and secure login process.
                  </Widget>
                  <Widget title="UK Data Centres">
                    GDPR compliant data centres, meeting Cyber Essentials,
                    ISO27001 and PASF standards.
                  </Widget>
                </WidgetContainer>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content1;
